const headerButtonData = [
  {
    title: "About Me",
    anchor: "about",
  },
  {
    title: "Experience",
    anchor: "experience",
  },
  {
    title: "Work",
    anchor: "work",
  },
  {
    title: "Contact",
    anchor: "contact",
  },
];
export { headerButtonData };
